<template>
  <div>
    <Header />
    <Mobileheader />
    <div class="mediumbreak"></div>
    <div class="careers_background" data-aos="fade-up" data-aos-duration="3000">
      <div class="mediumbreak"></div>

      <div class="careers_image_main">
        <b-img
          src="@/assets/images/careers/Screenshot 2022-08-09 173526.png"
          class="careers_img full-width"
        ></b-img>
      </div>

      <h1 class="careers_title">Career at Sindhiz!</h1>
      <p class="careers_para">
        To make a workplace great, it must be filled with great people.
        Everything we do, we do it with dedication for the benefit of our
        <br />
        stakeholders. Join our team of young software developers to be a part of
        an inspired workforce.
      </p>
    </div>
    <div class="mediumbreak"></div>
    <b-container data-aos="fade-up" data-aos-duration="3000">
      <div class="careers_positions">
        <div class="small_break"></div>
        <h1 class="careers_title">Developer Positions</h1>
        <div class="small_break"></div>
        <div>
          <b-container>
            <center>
              <b-card class="careers_card">
                <b-container>
                  <b-row>
                    <b-col md="6" lg="6">
                      <h2 class="careers_card_header">MEAN stack developer</h2>
                      <p class="careers_card_para">
                        Candidates should know Node.js proficiently. They should
                        be able to work on any Node.js application. Knowledge on
                        front-end development is required. The candidate should
                        be able to code a web application on Vue.js or similar
                        front-end framework with ease. Knowledge on server
                        deployment and Linux commands will be an added
                        advantage.
                      </p>
                    </b-col>
                    <b-col md="6" lg="6">
                      <b-button
                        class="careers_button"
                        variant="outline-dark"
                        v-b-modal.modal-select2
                        >Apply</b-button
                      >
                    </b-col>
                  </b-row>
                </b-container></b-card
              >
            </center>
          </b-container>

          <div class="mediumbreak"></div>
        </div>

        <h1 class="careers_title">Designer Positions</h1>
        <div class="small_break"></div>
        <div>
          <div class="mediumbreak"></div>
        </div>
      </div>
    </b-container>

    <!-- open modal -->
    <b-modal
      id="modal-select2"
      title="Apply for MEAN stack developer position"
      hide-footer
      size="md"
      cancel-variant="outline-secondary"
    >
      <!-- careers_form -->
      <validation-observer ref="careersValidation" #default="{ invalid }">
        <b-form @submit.prevent>
          <b-form-group label="Full Name" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="name"
                placeholder="Enter name"
                v-model="form.name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email" label-for="email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="Email"
                type="email"
                placeholder="Email"
                v-model="form.email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="CV" label-for="cv">
            <validation-provider
              #default="{ errors }"
              name="CV"
              rules="required"
            >
              <b-form-file
                placeholder="Choose a CV or drop it here..."
                drop-placeholder="Drop CV here..."
                no-drop
                accept="*"
                v-model="cv"
                @change="onChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Massage" label-for="Massage">
            <validation-provider
              #default="{ errors }"
              name="Massage"
              rules="required"
            >
              <b-form-textarea
                id="Massage"
                type="text"
                placeholder="Massage"
                v-model="form.massage"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <input
            type="text"
            id="Position"
            hidden
            value="MEAN stack developer position"
          />

          <div class="careers_mobile_break"></div>

          <b-button
            variant="primary"
            class="careers_modal_button"
            type="submit"
            @click="addCareers()"
            :disabled="invalid"
            >Submit Application</b-button
          >
        </b-form>
      </validation-observer>
    </b-modal>

    <div class="mediumbreak"></div>
    <Footer />
  </div>
</template>
<script>
import Header from "@/views/components/header.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  BForm,
  BFormFile,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BImg,
  BCard,
  BContainer,
  BRow,
  VBModal,
  BCol,
  BButton,
  BModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import CareersApi from "@/Api/Modules/careers";
import Mobileheader from "@/views/components/mobileheader.vue";
import Footer from "@/views/components/Footer.vue";
export default {
  components: {
    Mobileheader,
    BForm,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    Footer,
    BModal,
    BFormFile,
    VBModal,
    Header,
    BImg,
    BCard,
    BContainer,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      form: {},
      cv: "",
      // valications
      required,
      email,
    };
  },

  created() {
    AOS.init();
  },
  methods: {
    onChange(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.cv = e.target.result;
      };
    },

    async addCareers() {
      let data = new FormData();

      data.append("cv", this.cv);
      data.append("position", document.getElementById("Position").value);
      data.append("massage", this.form.massage);
      data.append("name", this.form.name);
      data.append("email", this.form.email);

      if (await this.$refs.careersValidation.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });

        await CareersApi.Add(data)
          .then(({ response }) => {
            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            this.$vs.loading.close();
          });
      }

      setTimeout(() => {
        this.form = {};
      }, 7000);
    },
  },

  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
